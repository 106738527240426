import { useEffect, useState } from 'react'

const useDarkMode = () => {
  const [themeMode, setThemeMode] = useState()
  const setMode = mode => {
    window.localStorage.setItem('theme', mode)
    setThemeMode(mode)
  };

  const toggleTheme = () => {
    if (themeMode === 'light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme')
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !localTheme ?
      setMode('dark') :
      localTheme ?
        setThemeMode(localTheme) :
        setMode('light')
  }, [])
  return {themeMode, toggleTheme}
}

export default useDarkMode