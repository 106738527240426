import React, {useState} from 'react'
import { useTheme, Menu } from 'evergreen-ui'
import { NAVIGATION_ITEMS } from '../constants'
import { css } from 'glamor'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

let rule = css({
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'red'
    }
  })

const MenuItem = ({menuitem, onSelect, selected}) => {
    const theme = useTheme()
    const [bgColor] = useState(theme.palette.neutral.light)
    const srcImage = selected ? menuitem.selectedImage : menuitem.image
    return (
        <Menu.Item
            borderRadius={4}
            padding={12}
            margin={4}
            backgroundColor={bgColor}
            style={theme.NavigationMenuItem}
            onSelect={()=>onSelect(menuitem)}
            className={rule}
            >
            <img src={srcImage} alt='' width={24} height={24} />
        </Menu.Item>
    )
}

const NavigationMenu = () => {
    const urlPath = window.location.pathname
    const pathname = urlPath === '/' ? '/twitter' : urlPath
    const [selected, setSelected] = useState(pathname)
    const history = useHistory()
    const menuSelectHandler = (selectedItem) => {
        setSelected(selectedItem.path)
        history.push(selectedItem.path)
    }
    return (
        <Menu>
            {
                NAVIGATION_ITEMS.map((item) => {
                    return(
                         <MenuItem
                            menuitem={item}
                            selected={item.path === selected}
                            key={item.path}
                            onSelect={menuSelectHandler}
                        />
                    )
                })
            }
        </Menu>
    )
}

export default NavigationMenu