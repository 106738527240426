import React, { useState } from 'react'
import { HeartIcon } from 'evergreen-ui'

const Favorite = ({isSelected=false, onChange=()=>{}}) => {
    const [selected, setSelected] = useState(isSelected)
    const iconColor = selected ? '#eec8a2' : '#F7F9FD'
    const iconClickHandler = () => {
        onChange(!selected)
        setSelected(!selected)
    }
    return <HeartIcon color={iconColor} size={24} cursor='pointer' onClick={iconClickHandler}/>
}

export default Favorite