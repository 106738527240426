import React from 'react'
import { Pane } from 'evergreen-ui'
import { useTheme } from 'evergreen-ui'

const ViewPort = ({children}) => {
    const theme = useTheme()
    return (
        <Pane
        minHeight='100vh'
        display='flex'
        flexDirection='row'
        backgroundColor={theme.colors.background.active}
        color='white'
        >
          {children}
        </Pane>
    )
}

export default ViewPort