import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TwitterModule from './modules/twitter/TwitterModule'
import RssModule from './modules/rss/RssModule'
import NewsLetterModule from './modules/newsLetter/NewsLetterModule'
import PodcastModule from './modules/podcast/PodcastModule'
import VideoModule from './modules/video/VideoModule'


const Routes = () => {
    return (
        <Switch>
          <Route path="/twitter">
            <TwitterModule />
          </Route>
          <Route path="/rss-feeds">
            <RssModule />
          </Route>
          <Route path="/news-letters">
            <NewsLetterModule />
          </Route>
          <Route path="/podcast">
            <PodcastModule />
          </Route>
          <Route path="/video">
            <VideoModule />
          </Route>
          <Route path="/">
            <TwitterModule />
          </Route>
        </Switch>
    )
}

export default Routes