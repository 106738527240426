import React from 'react'
import { SideSheet, Pane } from 'evergreen-ui'
import TwitterHeader from './TwitterHeader'
import TwitterBody from './TwitterBody'

const TwitterSideSheet = ({open=false, onSheetClose = () => {}, selectedAccount}) => {
    return(
        <SideSheet
            isShown={open}
            isClosing={true}
            onCloseComplete={() => onSheetClose()}
            containerProps={{
                display:'flex',
                flexDirection: 'column',
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                backgroundColor: '#14121E',
                overflow: 'hidden'
            }}
        >
        <Pane
          backgroundColor="#14121E"
          borderTopLeftRadius={20}
          borderBottomLeftRadius={20}
          display='flex'
          flexDirection='column'
          flex={1}
          >
            <TwitterHeader item={selectedAccount}/>
            <TwitterBody profile={selectedAccount} />
        </Pane>
        </SideSheet>

    )
}

export default TwitterSideSheet