import React, { Fragment } from 'react'
import AnimatedAvatar from './AnimatedAvatar'
import { Pane, Heading } from 'evergreen-ui'

const ProfileAvatar = ({profile, onListItemClick}) => {
    return (
        <Pane
        margin={10}
        width={80}
        onClick={() => onListItemClick(profile)}
        cursor='pointer'
        display='flex'
        flexDirection='column'
        alignItems='center'
        >
            <AnimatedAvatar src={profile.profile_image_url_https} />
            <Heading size={100} marginTop="default" textAlign='center'>{profile.name}</Heading>
        </Pane>
    )
}

const FavoriteContent = ({profile}) => {
    return (
        <Fragment>
            {
                profile.profile_banner_url ?
                <img src={profile.profile_banner_url} width={200} height='auto' alt='' style={{borderTopLeftRadius: 4, borderTopRightRadius: 4}}/>
                : <Pane height={66.55} />
            }
            <AnimatedAvatar src={profile.profile_image_url_https} marginTop={-40}/>
            <Heading size={200} textAlign='center' marginTop={8}>{profile.name}</Heading>
        </Fragment>
    )
}

const AddContent = () => {
    return <AnimatedAvatar marginTop={28} />
}

const FavoritePane = ({profile, onListItemClick}) => {
    const {type} = profile
    return (
        <Pane
        margin={10}
        width={200}
        cursor='pointer'
        backgroundColor='white'
        borderRadius={4}
        display='flex'
        flexDirection='column'
        alignItems='center'
        elevation={1}
        onClick={() => onListItemClick(profile)}
        >
            {
                type !== undefined ? <AddContent /> :  <FavoriteContent profile={profile} />
            }
        </Pane>
    )
}

const ShortList = ({data, onListItemClick=() => {}, type='profile'}) => {
    return (
        <Pane width='100%' display='flex' flexDirection='row' overflowY='hidden' flexWrap='wrap' height={140}>
            {
                data.map((item) => {
                    if(type !== 'profile'){
                        return <FavoritePane key={item.id} onListItemClick={onListItemClick} profile={item} />
                    }
                    return <ProfileAvatar profile={item} onListItemClick={onListItemClick} key={item.id}/>
                })
            }
        </Pane>
    )
}

export default ShortList