import twitter from '../images/twitter.svg'
import twitterSelected from '../images/twitterSelected.svg'
import rss from '../images/rss.svg'
import rssSelected from '../images/rssSelected.svg'
import letter from '../images/newsletter.svg'
import letterSelected from '../images/newsletterSelected.svg'
import video from '../images/video.svg'
import videoSelected from '../images/videoSelected.svg'
import podcast from '../images/podcast.svg'
import podcastSelected from '../images/podcastSelected.svg'

const THEME_MODE = {
    LIGHT: 'light',
    DARK: 'dark'
}

const NAVIGATION_ITEMS = [
    {module: 'Twitter', path: '/twitter', image: twitter, selectedImage: twitterSelected},
    {module: 'Rss', path: '/rss-feeds', image: rss, selectedImage: rssSelected},
    {module: 'News Letter', path: '/news-letters', image: letter, selectedImage: letterSelected},
    {module: 'Podcast', path: '/podcast', image: podcast, selectedImage: podcastSelected},
    {module: 'Video', path: '/video', image:video, selectedImage:videoSelected},
]

export { THEME_MODE, NAVIGATION_ITEMS }