import React, { useState } from 'react'
import TwitterSideSheet from './TwitterSideSheet'
import TwitterDevs from './TwitterDevs'
import TwitterResource from './TwitterResource'
import { Pane } from 'evergreen-ui'
import {useRecoilState} from 'recoil'
import selectedProfile from '../../hooks/selectedProfile'
import TwitterFavs from './TwitterFavs'

const favoriteItem = (item) => {
    const obj = {...item}
    const localFavs = JSON.parse(localStorage.getItem('twitterFav'))
    if(localFavs){
        localFavs.forEach((localItem) => {
            if(item.id === localItem.id){
                obj.favorite = localItem.favorite
            }
        })
    }
    return obj
}

const TwitterModule = () => {
    const [openSheet, setOpenSheet] = useState(false)
    const [selecProfile, setSelecProfile] = useRecoilState(selectedProfile)
    const listItemClickHandler = (item) => {
        setSelecProfile(favoriteItem(item))
        setOpenSheet(true)
    }
    return (
        <Pane display='flex' flexDirection='column' margin={20}>
            <TwitterDevs onTwitterDevClick={listItemClickHandler} />
            <TwitterResource onTwitterDevClick={listItemClickHandler} />
            <TwitterFavs onTwitterDevClick={listItemClickHandler} />
            <TwitterSideSheet
                open={openSheet}
                selectedAccount={selecProfile}
                onSheetClose={() => setOpenSheet(false)}
            />
        </Pane>
    )
}

export default TwitterModule