import React, { useState } from 'react'
import { Pane } from 'evergreen-ui'
import { useTheme } from 'evergreen-ui'
import light from '../images/sun.svg'
import dark from '../images/dark.svg'

const SwitchMode = ({onChange}) => {
    const theme = useTheme()
    const [isDarkMode, setIsDarkMode] = useState(true)
    const [bgColor] = useState(theme.palette.neutral.light)
    const imgSrc = isDarkMode ? dark : light

    const clickHandler = () => {
        setIsDarkMode(!isDarkMode)
        onChange(!isDarkMode ? 'light' : 'dark')
    }
    return (
        <Pane
            cursor='pointer'
            style={theme.NavigationMenuItem}
            backgroundColor={bgColor}
            borderRadius={4}
            padding={12}
            margin={4}
            onClick={clickHandler}
            >
            <img src={imgSrc} width={24} height={24} alt='dark' />
        </Pane>
    )
}

export default SwitchMode