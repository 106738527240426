import React from 'react'
import { Pane } from 'evergreen-ui'
import NavigationMenu from './NavigationMenu'
import AppLogo from './AppLogo'
import SwitchMode from './SwitchMode'


const Navigation = ({toggleTheme}) => {
    return (
        <Pane width={60}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            paddingBottom={15} >
            <AppLogo />
            <NavigationMenu />
            <SwitchMode onChange={toggleTheme} />
        </Pane>
    )
}

export default Navigation