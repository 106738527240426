import { atom } from 'recoil'

const getFavList = () => {
    let localFavs = JSON.parse(localStorage.getItem('twitterFav'))
    return localFavs || [{type:'add'}]
}

const twitterFavorite = atom({
    key: 'twitterFavorite',
    default: getFavList()
})

export default twitterFavorite