import { selector } from 'recoil'
import { queryBuilder } from './useTwitterDevs'

const useTwitterResource = selector({
    key: 'useTwitterResource',
    get: async ({get}) => {
       const ref = queryBuilder('resources')
       const snapshot = await ref.once('value')
       return Object.values(snapshot.val())
    }
})

export default useTwitterResource