import React, { Suspense } from 'react';
import { ThemeProvider, defaultTheme } from 'evergreen-ui'
import { BrowserRouter as Router } from 'react-router-dom'
import Navigation from './components/Navigaton'
import './App.css';
import Routes from './Routes';
import ViewPort from './components/Viewport';
import useDarkMode from './hooks/useDarkMode';

import { RecoilRoot } from 'recoil'
import { THEME_MODE } from './constants';
import { lightTheme, darkTheme } from './theme';
import merge from 'deepmerge'

function App() {
  const {toggleTheme, themeMode} = useDarkMode()

  if(!themeMode){
    return null
  }

  let activeTheme

  if(themeMode === THEME_MODE.LIGHT){
    activeTheme = merge(defaultTheme,lightTheme)
  } else {
    activeTheme = merge(defaultTheme,darkTheme)
  }

  return (
    <RecoilRoot>
      <Suspense fallback={<div>loading...</div>}>
        <ThemeProvider value={activeTheme}>
            <Router>
              <ViewPort>
                <Navigation toggleTheme={toggleTheme} />
                <Routes />
              </ViewPort>
            </Router>
        </ThemeProvider>
      </Suspense>
    </RecoilRoot>
  )
}

export default App;
