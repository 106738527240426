import React from 'react'
import { Pane, Spinner } from 'evergreen-ui'
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const TwitterBody = ({profile}) => {
    return(
        <Pane
            flex={1}
            backgroundColor='white'
            borderTopLeftRadius={20}
            borderTopRightRadius={20}
            borderBottomLeftRadius={20}
        >
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName={profile.screen_name}
                autoHeight
                noFooter
                noHeader
                theme='dark'
                placeholder={<Spinner />}
            />
        </Pane>
    )
}

export default TwitterBody