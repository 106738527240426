import firebase from 'firebase/app'
import 'firebase/database'

const firebaseConfig = {
    apiKey: 'AIzaSyCFv4_jdUPaGtBAq0dmWmkEXhDTCRtOxNo',
    authDomain: 'popularjs-15c9c.firebaseapp.com',
    databaseURL: 'https://popularjs-15c9c.firebaseio.com',
    projectId: "popularjs-15c9c",
    storageBucket: 'popularjs-15c9c.appspot.com'
};

firebase.initializeApp(firebaseConfig);


export { firebase }