import React from 'react'
import { Pane } from 'evergreen-ui'
import TwitterProfile from './TwitterProfile'

const TwitterHeader = ({open=false, onSheetClose = () => {}, item}) => {
    return(
        <Pane backgroundColor='#14121E' borderTopLeftRadius={20} height={300}>
            <TwitterProfile profile={item} />
        </Pane>
    )
}

export default TwitterHeader
