import React from 'react'
import ShortList from '../../components/ShortList'
import {useRecoilValue} from 'recoil'
import { Pane, Heading } from 'evergreen-ui'
import twitterFavorite from '../../hooks/twitterFavorite'

const TwitterFavs = ({onTwitterDevClick}) => {
    const twitterFavData = useRecoilValue(twitterFavorite)
    return (
        <Pane display='flex' flexDirection='column'>
            <Pane display='flex' flexDirection='row' justifyContent='space-between' paddingLeft={20} paddingBottom={10} marginRight={60} alignItems='center'>
                <Heading size={600} color='white'>Favorite</Heading>
                <Heading size={200} color='white' cursor='pointer'>Watch All</Heading>
            </Pane>
            <ShortList data={twitterFavData} onListItemClick={onTwitterDevClick} type='fav'/>
        </Pane>
    )
}

export default TwitterFavs