import { selector } from 'recoil'
import { firebase} from '../services/firestore'

export const queryBuilder = (type) => {
    const orderByKeys = ['name', 'screen_name', 'location', 'id', 'followers_count', 'friends_count', 'favourites_count']
    const orderKey = orderByKeys[Math.floor(Math.random() * orderByKeys.length)]
    const limit = ['first', 'last']
    const limitKey = limit[Math.floor(Math.random() * limit.length)]
    const ref = firebase.database().ref(`popularjs/twitter/${type}`).orderByChild(orderKey)
    if(limitKey === 'first'){
        return ref.limitToFirst(30)
    }
    return ref.limitToLast(30)
}


const useTwitterDevs = selector({
    key: 'useTwitterDevs',
    get: async ({get}) => {
       const ref = queryBuilder('devs')
       const snapshot = await ref.once('value')
       return Object.values(snapshot.val())
    }
})

export default useTwitterDevs