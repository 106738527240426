import React from 'react'
import ShortList from '../../components/ShortList'
import {useRecoilValue} from 'recoil'
import useTwitterDevs from '../../hooks/useTwitterDevs'
import { Pane, Heading } from 'evergreen-ui'

const TwitterDevs = ({onTwitterDevClick}) => {
    const twitterData = useRecoilValue(useTwitterDevs)
    return (
        <Pane display='flex' flexDirection='column'>
            <Pane display='flex' flexDirection='row' justifyContent='space-between' paddingLeft={20} paddingBottom={10} marginRight={60} alignItems='center'>
                <Heading size={600} color='white'>Popular Devs</Heading>
                <Heading size={200} color='white' cursor='pointer'>Watch All</Heading>
            </Pane>
            <ShortList data={twitterData} onListItemClick={onTwitterDevClick}/>
        </Pane>
    )
}

export default TwitterDevs