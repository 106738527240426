
const NavigationMenuItem = {
    width: 42,
    height: 42,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const lightTheme = {
    colors: {
        background: {
            active: '#ffffff'
        }
    },
    scales:{
        neutral: {
            N1A: '#7B8B9A'
        },
        blue: {
            B1A: '#7B8B9A'
        }

    },
    NavigationMenuItem
}

const darkTheme = {
    colors: {
        background: {
            active: '#14121E'
        }
    },
    NavigationMenuItem
}

export { lightTheme, darkTheme }