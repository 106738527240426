import React from 'react'
import ShortList from '../../components/ShortList'
import {useRecoilValue} from 'recoil'
import { Pane, Heading } from 'evergreen-ui'
import useTwitterResource from '../../hooks/useTwitterResource'

const TwitterResource = ({onTwitterDevClick}) => {
    const twitterData = useRecoilValue(useTwitterResource)
    return (
        <Pane display='flex' flexDirection='column' marginTop={50}>
            <Pane display='flex' flexDirection='row' justifyContent='space-between' paddingLeft={20} paddingBottom={10} marginRight={60} alignItems='center'>
                <Heading size={600} color='white'>Popular Resources</Heading>
                <Heading size={200} color='white' cursor='pointer'>Watch All</Heading>
            </Pane>
            <ShortList data={twitterData} onListItemClick={onTwitterDevClick}/>
        </Pane>
    )
}

export default TwitterResource