import React from 'react'
import { Pane, GeolocationIcon, Heading, Button } from 'evergreen-ui'
import AnimatedAvatar from '../../components/AnimatedAvatar'
import Favorite from '../../components/Favorite'
import { useSetRecoilState } from 'recoil'
import twitterFavorite from '../../hooks/twitterFavorite'

const FormatNum = (num,digits) => {
    return new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1,notation: "compact" , compactDisplay: "short" }).format(num)
}

const favoriteList = (profile, selected) => {
    let localFavs = JSON.parse(localStorage.getItem('twitterFav'))
    let saveToLocalStorageList = []
    if(selected){
        const obj = {...profile, favorite: selected}
        if(localFavs !== null) {
            localFavs.push(obj)
        }else{
            localFavs = [obj]
        }
        saveToLocalStorageList = localFavs
    } else {
        saveToLocalStorageList = localFavs.filter((item, indx) => item.id !== profile.id)
    }
    localStorage.setItem('twitterFav', JSON.stringify(saveToLocalStorageList))
    return saveToLocalStorageList
}

const TwitterProfile = ({profile}) => {

    const setTwitterFav = useSetRecoilState(twitterFavorite)

    const favoriteClickHandler = (selected) => {
        setTwitterFav(favoriteList(profile, selected))
    }

    return (
        <Pane
            display='flex'
            flexDirection='column'
            marginTop={50}
            paddingLeft= {50}
            >
            <Pane display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Pane display='flex' flexDirection='row'>
                    <AnimatedAvatar src={profile.profile_image_url_https} />
                    <Pane display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginLeft={20} >
                        <Heading size={800} color='#F9F9FB'>
                            {profile.name}
                        </Heading>
                        {
                            profile.location === '' ? null : (
                                <Pane display='flex' flexDirection='row' justifyContent='center' alignItems='center' marginTop={5}>
                                    <GeolocationIcon color='#eec8a2' marginRight={10} />
                                    <Heading size={200} color='white'>
                                        {profile.location}
                                    </Heading>
                                </Pane>
                            )
                        }
                    </Pane>
                </Pane>
                <Pane display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex='1'>
                    <Favorite isSelected={profile.favorite} onChange={favoriteClickHandler}/>
                </Pane>
            </Pane>
            <Pane display='flex' flexDirection='row' marginTop={60} justifyContent='space-between' alignItems='center' paddingRight={60} >
                <Pane display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                    <Heading size={700} color='#F9F9FB'>
                        {FormatNum(profile.followers_count)}
                    </Heading>
                    <Heading size={300} color='#F9F9FB'>
                        Followers
                    </Heading>
                </Pane>
                <Pane width={1} height={30} backgroundColor='#66788A' />
                <Pane display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                    <Heading size={700} color='#F9F9FB'>
                        {FormatNum(profile.friends_count)}
                    </Heading>
                    <Heading size={300} color='#F9F9FB'>
                        Following
                    </Heading>
                </Pane>
                <Pane width={1} height={30} backgroundColor='#66788A' />
                <Button height={32}> Follow </Button>
            </Pane>
        </Pane>
    )
}

export default TwitterProfile