import React from 'react'
import { Pane, Avatar, AddIcon } from "evergreen-ui";

const AnimatedAvatar = ({src, ...rest}) => {
    return <Pane
    width={60}
    height={60}
    background='linear-gradient(180deg, #EF629F 0%, #EECDA3 100%);'
    // backgroundColor='#14121E'
    borderRadius={30}
    marginTop={10}
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
    elevation={2}
    {...rest}
   // boxShadow='0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1)'
    >
    { src ? <Avatar src={src} size={54} /> : <AddIcon />}
    </Pane>
}

export default AnimatedAvatar